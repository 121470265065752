<template>
	<div class="publicEmergencies">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline search-form">
				<el-form-item label="市：">
					<el-select v-model="formInline.city" placeholder="请选择市" disabled>
						<el-option v-for="item in []" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select v-model="formInline.county" placeholder="请选择区县" disabled>
						<el-option v-for="item in []" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="乡镇：">
					<el-select v-model="formInline.townCode" placeholder="请选择" @change="changeEconomize">
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="村：">
					<el-select v-model="formInline.villageCode" placeholder="请选择">
						<el-option v-for="item in villageList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item> -->
				<!-- <el-form-item label="时间：">
					<el-date-picker
						v-model="times"
						type="datetimerange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						value-format="yyyy-MM-dd HH:mm:ss"
					>
					</el-date-picker>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo(scope.row)" size="mini" type="primary"> 查看 </el-button>
				</template>
			</Table>
		</div>
	</div>
</template>

<script>
import { getAge } from '@/utils/formatTime.js';
export default {
	name: 'infectiousDisease',
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			loading: false,
			formInline: {
				city: '',
				county: '',
				townCode: '',
				villageCode: '',
			},
			times: [],
			townList: [],
			villageList: [],
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '姓名',
					prop: 'eventName',
					formatter: (row) => {
						return row.eventName || '-';
					},
				},
				{
					label: '性别',
					prop: 'reportType',
					formatter: (row) => {
						return row.reportType || '-';
					},
				},
				{
					label: '年龄',
					prop: 'age',
					formatter: (row) => {
						return row.age ? getAge(row.age) : '-';
					},
				},
				{
					label: '传染病类型',
					prop: 'infectiousType',
					formatter: (row) => {
						return row.infectiousType || '-';
					},
				},
				{
					label: '传染病名称',
					prop: 'infectiousName',
					formatter: (row) => {
						return row.infectiousName || '-';
					},
				},
				{
					label: '住址',
					prop: 'address',
					formatter: (row) => {
						return row.address || '-';
					},
				},
				{
					label: '发现时间',
					prop: 'findTime',
					formatter: (row) => {
						return row.findTime || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
			],
			isShowDetail: false,
			options: [],
			filterLoading: false,
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		if (userInfo && userInfo.chinaRegionDtoList && userInfo.chinaRegionDtoList.length > 0) {
			this.formInline.city = userInfo.chinaRegionDtoList[1].title;
			this.formInline.county = userInfo.chinaRegionDtoList[2].title;
		}
		this.getList();
		// this.getTownList();
	},
	methods: {
		// 获取乡镇/街道
		getTownList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code: JSON.parse(sessionStorage.getItem('userInfo')).chinaRegionDtoList[2].code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.townList = res.data.collection || [];
						this.townList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		changeEconomize(code) {
			this.formInline.villageCode = '';
			this.villageList = [];
			if (!code || code == '') return;
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.villageList = res.data.collection || [];
						this.villageList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? (this.filterLoading = true) : (this.loading = true);
			let par = {
				contentCode: 'IFDS',
				...this.formInline,
				...this.queryData,
			};
			this.$http
				.get('/diagnosis-api/v1/PersonForms.json', { params: par })
				.then((res) => {
					if (res.data && res.data.success) {
						let collection = res.data.collection || [];
						this.loading = false;
						if (collection.length == 0) return;
						collection = collection.map((item) => {
							return {
								...item,
								content: item.content ? JSON.parse(item.content) : {},
							};
						});
						let arr = collection.map((item) => {
							if (JSON.stringify(item.content) !== '{}') {
								let infectiousArr = [item.content.items[15].items[0], item.content.items[16].items[0], item.content.items[17].items[0]];
								return {
									id: item.id,
									eventName: item.content.items[2].items[0].value?.toString() || '-',
									reportType: item.content.items[4].items[0].value?.toString() || '-',
									age: item.content.items[5].items[0].value?.toString() || '-',
									address: item.content.items[9].items[0].value?.toString() || '-',
									findTime: item.content.items[12].items[0].value?.toString() || '-',
									phoneNumber: item.content.items[7].items[0].value?.toString() || '-',
									infectiousType:
										infectiousArr
											.filter((v) => v.value && v.value.length !== 0)
											.map((v) => v.title)
											.join(',') || '-',
									infectiousName:
										infectiousArr
											.filter((v) => v.value && v.value.length !== 0)
											.map((v) => v.value)
											.join(',') || '-',
								};
							}
						});
						if (type == 'filter') {
							this.filterLoading = false;
							this.options = arr.map((item) => {
								return {
									...item,
									value: item.eventName,
								};
							});
						} else {
							this.tableData = JSON.parse(JSON.stringify(arr)) || [];
							this.total = res.data.pagination.totalItems || 0;
						}
					}
				})
				.catch((e) => {});
		},
		modifyParkInfo(row) {
			this.$router.push({
				path: '/publicHealth/infectiousDisease/details',
				query: {
					id: row.id,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
	.el-date-editor {
		width: 360px;
		.el-range-separator {
			display: inline-block;
			height: 14px;
			line-height: 14px;
			padding: 0;
		}
		.el-range-input {
			width: 80%;
		}
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>